.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.ant-input-number-handler-wrap {
  display: none;
} 

.editor-wrapper {
  height: 250px;
  max-height: 600px;
}
.editor-main {

}



.add-button-div {
  display: flex !important;
  justify-content: end;
}

.disabled-row {
  /* opacity: 0.5; */
  background-color: rgba(128, 128, 128, 0.16);
}